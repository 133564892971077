import React, { Component } from "react"

export class BannerAfterMainSection extends Component {
    render() {
        return (
            <div>
                <div className="bg-newlndgray pt-[1400px] md:pt-[660px] lg:pt-[530px] 2xl:pt-[440px]">
                    <div className="grid grid-cols-3 md:grid-cols-6 px-4 pt-14 pb-8 mx-auto 2xl:flex 2xl:flex-row 2xl:space-x-16 items-start gap-8 w-full sm:justify-center">
                        {this.props.homePageData[0].node.bannerAfterMainSectionHeaderList.map((content, index) => {
                            // Split the content into words
                            const words = content.content.split(" ");

                            let formattedContent;
                            if (words.length === 2) {
                                // If there are exactly 2 words, make only the first word bold
                                formattedContent = (
                                    <>
                                        <strong>{words[0]}</strong> {words[1]}
                                    </>
                                );
                            } else {
                                // Otherwise, make the first two words bold
                                const firstTwoWords = words.slice(0, 2).join(" ");
                                const restOfTheText = words.slice(2).join(" ");
                                formattedContent = (
                                    <>
                                        <strong>{firstTwoWords}</strong> {restOfTheText}
                                    </>
                                );
                            }

                            return (
                                <div key={index}>
                                    <div className="text-[10px] lg:text-[12px] text-center">
                                        {formattedContent}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="container mx-auto pt-10 lg:pt-16">
                    <div className="px-5 mx-auto">
                        <p className="text-lndred text-sm text-left xl:px-24 2xl:px-[192px]">Maximize Value</p>
                        <p className="font-bold text-3xl text-left xl:px-24 2xl:px-[190px]">
                            {this.props.homePageData[0].node.bannerAfterMainSectionTitle}
                        </p>

                        <div className="text-left xl:px-24 2xl:px-[190px] pt-4" dangerouslySetInnerHTML={{
                            __html: this.props.homePageData[0].node.bannerAfterMainSectionContent.childMarkdownRemark.html,
                        }} />

                        <div className="mx-auto pt-7 xl:px-24 2xl:px-[190px]">
                            <div className="bg-newlndgray p-5">
                                <div className="py-3 px-5">
                                    <ul className="space-y-3 list-decimal marker:text-lndred marker:font-bold">
                                        {this.props.homePageData[0].node.bannerAfterMainSectionList.map(content =>
                                            <div key={content.content}>
                                                <li>{content.content}</li>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}